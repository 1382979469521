<template>
  <div>
    <el-card
      :body-style="{ padding: '2%' }"
      style="margin: 1.5%"
      shadow="never"
    >
      <div class="aiuda">
        <span style="font-size: 24px; font-weight: 600">Modificar Centro</span>
        <el-button class="button" @click="$router.go(-1)"
          >Volver atrás</el-button
        >
      </div>
      <el-divider />
      <el-form
        v-loading="loading"
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="top"
        label-width="130px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Razon Social" prop="razonSocial">
              <el-input maxlength="20" v-model="form.razonSocial"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="CUIT" prop="CUIT">
              <input-mask
                class="el-input__inner"
                v-model="form.cuit"
                mask="99-99.999.999-9"
                maskChar=" "
              ></input-mask>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Email" prop="email">
          <el-input
            class="inputEmail"
            maxlength="300"
            v-model="form.email"
          ></el-input>
        </el-form-item>

        <el-form-item label="Domicilio" prop="domicilio">
          <el-input maxlength="30" v-model="form.domicilio"></el-input>
        </el-form-item>

        <div class="contenedor" v-if="modificarLocalidad">
          <el-form-item label="Departamento">
            <el-select
              v-model="form.departamentoID"
              class="m-2"
              placeholder="Seleccione un departamento de la lista"
              @change="cambioDepartamento"
            >
              <el-option
                v-for="item in departamentosSelect"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-loading="cargandoLocalidades" label="Localidad">
            <el-select
              :disabled="!form.departamentoID"
              v-model="form.localidadID"
              class="m-2"
              placeholder="Seleccione una localidad de la lista"
              @change="cambioLocalidad"
            >
              <el-option
                v-for="item in localidadesSelect"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="contenedor">
          <el-form-item label="Formato de coordenadas" prop="latLongFloat">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueFormatoDecimales"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Decimales"
              inactive-text="Grados, minutos y segundos"
            >
            </el-switch>

            <label style="color: gray; font-style: italyc"
              >Se almacenará el formato seleccionado</label
            >
          </el-form-item>

          <div v-if="valueFormatoDecimales">
            <el-form-item label="Latitud" prop="latitud">
              <el-input v-model="form.latitud" @input="onPasteLat"></el-input>
            </el-form-item>

            <el-form-item label="Longitud" prop="longitud">
              <el-input v-model="form.longitud" @input="onPasteLong"></el-input>
            </el-form-item>
          </div>

          <div v-else>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="." prop="grados">
                  <label>Latitud</label>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Grados" prop="gradosLat">
                  <el-input v-model.number="form.gradosLat">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Minutos" prop="minutosLat">
                  <el-input v-model.number="form.minutosLat">
                    <template slot="append">'</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Segundos" prop="segundosLat">
                  <el-input v-model.number="form.segundosLat">
                    <template slot="append">''</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Direccion" prop="valueCardinalLat">
                  <el-select
                    v-model="form.valueCardinalLat"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="." prop="grados">
                  <label>Longitud</label>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Grados" prop="gradosLon">
                  <el-input v-model.number="form.gradosLon">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Minutos" prop="minutosLon">
                  <el-input v-model.number="form.minutosLon">
                    <template slot="append">'</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Segundos" prop="segundosLon">
                  <el-input v-model.number="form.segundosLon">
                    <template slot="append">''</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Direccion" prop="valueCardinalLon">
                  <el-select
                    v-model="form.valueCardinalLon"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Teléfono" prop="telefono">
              <el-input maxlength="15" v-model="form.telefono"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Tipo de Centro" prop="tipo">
          <maca-select-box
            v-model="form.tipo"
            :url="urlTiposBeneficiarios"
          ></maca-select-box>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Traslados" prop="traslados">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueTraslados"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            let params = { paisID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                console.log(res.datos);
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 3) {
            //  form.departamento = node.data.value;

            let params = { departamentoID: node.data.value, sinPaginar: 1 };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                console.log(res.datos);
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            //  form.localidad = node.data.value;
            let params = { localidadID: node.data.value, sinPaginar: 1 };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              console.log(res.datos);
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },

      form: {
        nombre: null,
        apellido: null,
        razonSocial: null,
        cuit: null,
        domicilio: null,
        pais: null,
        provincia: null,
        departamentoID: null,
        departamento: null,
        localidadID: null,
        localidad: null,
        latitud: null,
        longitud: null,
        tipo: null,
        telefono: null,
        email: null,
        traslado: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,
      },
      impedirClose: false,
      persona: null,
      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",
      valueFormatoDecimales: true,
      puntosCardinales: [
        {
          value: "N",
          label: "N",
        },
        {
          value: "S",
          label: "S",
        },
        {
          value: "W",
          label: "W",
        },
        {
          value: "E",
          label: "E",
        },
      ],

      formRules: {
        gradosLat: [
          {
            required: true,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],

        minutosLat: [
          {
            required: true,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: true,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: true,
            message: "Por favor introduzca dirección.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: true,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: true,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: true,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: true,
            message: "Por favor introduzca dirección.",
            trigger: "change",
          },
        ],
        dni: [
          {
            required: true,
            message: "Por favor introduzca el DNI.",
            trigger: "change",
          },
        ],

        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],
        telefono: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
        traslado: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],

        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor introduzca un email correcto",
            trigger: ["blur", "change"],
          },
        ],
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,

      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,
      detalleBeneficiarioActual: null,
      detalleBeneficiarioIDNuevo: null,

      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,
      departamentosSelect: [],
      localidadesSelect: [],
      cargandoLocalidades: false,
      tipoBenefeciarios: [],
      loading: false,
      modificarLocalidad: true,
    };
  },
  created() {
    if (this.$refs.form) {
      this.$refs.form.resetFields();
    }
    this.id = this.$route.params.id;
    this.getDatos();
    this.getTiposDeBneficiario();
    this.getDepartamentos();
  },
  methods: {
    //convertir grados min seg y cardinalidad a lat y lng
    ConvertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },
    ParseDMS(input) {
      var parts = input.split(/[^\d\w]+/);

      var lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
      var lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);
    },

    getPais(addrComponents) {
      for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] == "country") {
          return addrComponents[i].short_name;
        }
        if (addrComponents[i].types.length == 2) {
          if (addrComponents[i].types[0] == "political") {
            return addrComponents[i].short_name;
          }
        }
      }
      return false;
    },

    async verificarCoordenadas() {
      let resultado;
      try {
        let geocoder = new google.maps.Geocoder(),
          latlng = new google.maps.LatLng(
            this.form.latitud,
            this.form.longitud
          );
        const result = await geocoder.geocode(
          { latLng: latlng },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                resultado = { status: "OK", result: results[1] };
              } else {
                resultado = { status: "INVALID", result: results };
              }
            } else {
              //failed

              resultado = { status: "INVALID", result: results[1] };
            }
          }
        );
      } catch (error) {
        this.$message({
          message:
            "Error al utilizar los servicios de google. Detalle:" +
            error.message,
          type: "error",
        });
        return false;
      }
      console.log("resultado", resultado);
      if (resultado.status != null && resultado.status == "OK") {
        const pais = this.getPais(resultado.result.address_components);
        if (pais != null && pais == "AR") {
          return true;
        } else {
          this.$message({
            message:
              "Se encuentra fuera de argentina, seleccione otras coordenadas.",
            type: "error",
          });
        }
        return false;
      } else {
        this.$message({
          message: "Coordenadas invalidas.",
          type: "error",
        });
        return false;
      }
    },
    onPasteLat() {
      let latLong = this.form.latitud;
      latLong = latLong.split(", ");
      this.form.latitud = latLong[0];
      this.form.longitud = latLong[1];
      console.log(latLong);
    },
    onPasteLong() {
      let latLong = this.form.longitud;
      latLong = latLong.split(", ");
      this.form.latitud = latLong[0];
      this.form.longitud = latLong[1];
      console.log(latLong);
    },
    cambioDepartamento() {
      this.getLocalidades();
    },
    cambioLocalidad() {},
    async getTiposDeBneficiario() {
      await this.$maca.api.get(this.urlTiposBeneficiarios).then((res) => {
        this.tiposBeneficiarioSelect = res.datos;
      });
    },
    async getDepartamentos() {
      let params = {
        provinciaID: 1,
        sinPaginar: 1,
      };
      await this.$maca.api.get(this.urlDepartamento, params).then((res) => {
        this.departamentosSelect = res.datos;
      });
    },
    async getLocalidades() {
      this.cargandoLocalidades = true;

      let params = {
        departamentoID: this.form.departamentoID,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/localidad/obtenerTodos", params)
        .then((res) => {
          this.localidadesSelect = res.datos;
        });
      this.cargandoLocalidades = false;
    },
    resetearValor(valor) {
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.$refs.refProvinciaMobile.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
        this.eligioPais = true;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.$refs.refDepartamentoMobile.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.$refs.refLocalidadMobile.recargar();
        this.form.localidad = null;
      }
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaN.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    async getDatos() {
      this.loading = true;

      let respuestaApi = await this.$maca.api.get("/centro/obtenerDatos", {
        id: this.id,
      });

      this.loading = false;
      console.log(respuestaApi);
      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.nombre;
        this.form.apellido = respuestaApi.datos.apellido;
        this.form.email = respuestaApi.datos.email;
        this.form.dni = respuestaApi.datos.dni;
        this.form.domicilio = respuestaApi.datos.domicilio;

        this.form.latitud = respuestaApi.datos.latitud;
        this.form.longitud = respuestaApi.datos.longitud;
        this.form.gradosLat = respuestaApi.datos.latitudDMS.grados;
        this.form.minutosLat = respuestaApi.datos.latitudDMS.minutos;
        this.form.segundosLat = respuestaApi.datos.latitudDMS.segundos;
        this.form.valueCardinalLat = respuestaApi.datos.latitudDMS.direccion;
        this.form.gradosLon = respuestaApi.datos.longitudDMS.grados;
        this.form.minutosLon = respuestaApi.datos.longitudDMS.minutos;
        this.form.segundosLon = respuestaApi.datos.longitudDMS.segundos;
        this.form.valueCardinalLon = respuestaApi.datos.longitudDMS.direccion;

        this.form.telefono = respuestaApi.datos.telefono;
        this.form.cuit = respuestaApi.datos.cuit;
        this.form.razonSocial = respuestaApi.datos.razonSocial;
        this.form.traslado = respuestaApi.datos.traslado;
        if (this.form.traslado == 1) {
          this.valueTraslados = true;
        } else {
          this.valueTraslados = false;
        }
        this.form.paraje = respuestaApi.datos.detalleParaje;
        if (respuestaApi.datos.detalleParaje != null) {
          this.parajeID = this.form.paraje.id;
        }
        if (respuestaApi.datos.detalleDepartamento != null) {
          await this.getLocalidades();
          this.form.departamento = respuestaApi.datos.detalleDepartamento;
          this.form.departamentoID = this.form.departamento.id;
        }
        if (respuestaApi.datos.detalleLocalidad != null) {
          this.form.localidad = respuestaApi.datos.detalleLocalidad;
          this.form.localidadID = this.form.localidad.id;
        }

        if (respuestaApi.datos.detalleProvincia != null) {
          this.form.provincia = respuestaApi.datos.detalleProvincia;
        }

        this.form.tipo =
          respuestaApi.datos.detalleCentroTipoBeneficiario[0].detalleTipoBeneficiario;
        this.detalleBeneficiarioActual =
          respuestaApi.datos.detalleCentroTipoBeneficiario != null
            ? respuestaApi.datos.detalleCentroTipoBeneficiario
            : null;
      } else {
        this.$router.go(-1);
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$router.go(-1);
        }
      });
    },
    async postApi() {
      if (!this.valueFormatoDecimales) {
        this.form.latitud = this.ConvertDMSToDD(
          this.form.gradosLat,
          this.form.minutosLat,
          this.form.segundosLat,
          this.form.valueCardinalLat
        );
        this.form.longitud = this.ConvertDMSToDD(
          this.form.gradosLon,
          this.form.minutosLon,
          this.form.segundosLon,
          this.form.valueCardinalLon
        );
      }
      const result = await this.verificarCoordenadas();
      if (result == false) {
        return false;
      }
      let detalleTipoBeneficiario = [];
      if (this.detalleBeneficiarioActual) {
        detalleTipoBeneficiario.push(
          {
            id: this.detalleBeneficiarioActual[0].id,
            tipoBeneficiarioID: this.form.tipo.id,
            accion: "B",
          },
          {
            id: this.detalleBeneficiarioActual[0].id,
            tipoBeneficiarioID: this.form.tipo.id,
            accion: "A",
          }
        );
      } else {
        detalleTipoBeneficiario.push({
          id: 0,
          tipoBeneficiarioID: this.form.tipo.id,
          accion: "A",
        });
      }

      let latitudDMS;
      let longitudDMS;
      if (!this.valueFormatoDecimales) {
        this.form.latitud = null;
        this.form.longitud = null;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };

        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
      }

      //quitar marcara al cuit
      let cuitcuilFormateado = this.form.cuit
        .replaceAll("-", "")
        .replaceAll(".", "")
        .replaceAll(" ", "");

      // Hacer Post
      let params = {
        id: this.id,
        razonSocial: this.form.razonSocial,
        cuit: cuitcuilFormateado,
        domicilio: this.form.domicilio,
        email: this.form.email,

        latitud: this.form.latitud,
        longitud: this.form.longitud,
        // latitudDMS: latitudDMS != null ? JSON.stringify(latitudDMS) : "",
        // longitudDMS: longitudDMS != null ? JSON.stringify(longitudDMS) : "",
        telefono: this.form.telefono,
        traslado: this.valueTraslados,
        detalleCentroTipoBeneficiario: JSON.stringify(detalleTipoBeneficiario),
      };

      if (!this.valueFormatoDecimales) {
        params.latitud = null;
        params.longitud = null;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };

        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
        params.latitudDMS = JSON.stringify(latitudDMS);
        params.longitudDMS = JSON.stringify(longitudDMS);
      }

      if (this.form.departamentoID != null) {
        params.departamentoID = this.form.departamentoID;
      }
      if (this.form.localidadID != null) {
        params.localidadID = this.form.localidadID;
      }

      //EN CASO DE QUE ME PIDAN PARAJE
      // parajeID: this.form.parajeID != null ? this.parajeID : "",
      let respuestaApi = await this.$maca.api.post(
        "/centro/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Centro actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>

<style scoped>
.aiuda {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>
